.pdfsite input, .pdfsite button {
  font: inherit;
}
.pdfsite header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0,0,0,0.5);
  padding: 20px;
  color: #ff0000;
}
.pdfsite header h1 {
  font-size: inherit;
  margin: 0;
}
.pdfsite__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}
.pdfsite__container__load {
  margin-top: 1em;
  color: white;
}
.pdfsite__container__document {
  margin: 1em 0;
}
.pdfsite__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdfsite__container__document .react-pdf__Page {
  max-width: calc(100vw);
  box-shadow: 0 0 8px rgba(0,0,0,0.5);
  margin: 1em;
}
.pdfsite__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
.pdfsite__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}
