.biography {
    font-weight: 400 !important;
    text-align: left;
}

.biography p:first-letter {
    text-transform: capitalize;
    color: #636d76;
    font-size: 18px;
    font-weight: 800 !important;
}
  
@media screen and (min-width: 600px) {
    .biography {
        column-count: 2;
        column-gap: 40px;
    }
}

@media screen and (min-width: 1000px) {
    .biography {
        column-count: 3;
        column-gap: 40px;
    }
}